.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 20px;
  flex-grow: 1;
  margin: 10;
}

.notification-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 10px;
  flex-grow: 1;
  margin: 10;
}

.memo-area-div {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.button-area-div {
  display: flex;
  justify-content: center;
  padding: 20px;
}

table {
  width: 100%;
  table-layout: fixed;
}

table td {
  overflow-wrap : break-word;
  border: 1px solid #ddd;
  padding: 4px;
}

ul {
  list-style: none;
  padding-left: 0;
}

.mark-invalid {
  background-color:#FFEEE5
}

.mark-valid {
  background-color:#E5F6FF
}